:root {
	--body-color: #181616;
	--accent-color: #009688;
	--text-color: #e5e5e5;
	--dark-color: #333030;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

html {
	accent-color: var(--accent-color);
}

body {
	margin: 0;
	padding: 0;

	color: var(--text-color);
	font-family: "Mulish", sans-serif;

	background-color: var(--body-color);
	overflow-x: hidden;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.navbar {
	position: fixed;
	inset: 0 0 auto;
	padding: 1rem;
	background: #121111;

	ul {
		display: flex;
		align-items: center;
		gap: 1rem;
		margin: 0;
		padding: 0;
		list-style: none;
	}

	a {
		display: flex;
		align-items: center;
		color: #fff;
		opacity: 0.4;
		text-decoration: none;
	}

	svg {
		display: flex;
		align-items: center;
	}

	li {
		display: flex;
		align-items: center;

		&.is-active a {
			opacity: 1;
		}
	}
}

.App {
	@media (width >= 64.98em) {
		display: grid;
		min-height: 100vh;
		place-content: center;
		overflow-x: hidden;
	}

	@media (width <= 64em) {
		margin-block-start: 20dvh;
	}
}

h1 {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	margin-block-start: 0;

	font-size: 3rem;
	font-weight: 1000;
	text-align: center;
	text-transform: uppercase;

	span {
		text-transform: none;
		font-family: "DM Serif Display", serif;
		font-weight: 600;
		font-size: 1.4rem;
		color: var(--accent-color);
	}

	&::after {
		content: "";

		width: 6rem;
		height: 4px;
		margin-block-start: 2rem;

		background-color: var(--dark-color);
	}
}

.scale-selector {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 0.5rem;
	margin-block-end: 2rem;
	padding-inline: 1rem;

	label {
		display: flex;
		font-family: "DM Serif Display", serif;
		letter-spacing: 0.09rem;
		font-weight: 600;
		font-size: 1rem;
		color: var(--text-color);
	}

	select {
		appearance: none;
		padding: 0.7rem 1.5rem;

		font-size: 1.15rem;
		font-weight: 1000;
		color: var(--text-color);

		border: 1px solid var(--dark-color);
		border-radius: 100vmax;
		background-color: var(--body-color);
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' fill='none'%3E%3Cpath fill='%23e5e5e5' fill-rule='evenodd' d='M4.932 5.432a.45.45 0 1 0 .636.636L7.5 4.136l1.932 1.932a.45.45 0 0 0 .636-.636l-2.25-2.25a.45.45 0 0 0-.636 0l-2.25 2.25Zm5.136 4.136a.45.45 0 0 0-.636-.636L7.5 10.864 5.568 8.932a.45.45 0 0 0-.636.636l2.25 2.25a.45.45 0 0 0 .636 0l2.25-2.25Z' clip-rule='evenodd'/%3E%3C/svg%3E");
		background-repeat: no-repeat;
		background-position: 95% 50%;
		background-size: 1.2rem;
		cursor: pointer;
		user-select: none;

		&:focus-visible,
		&:focus {
			outline: 0;
			border-color: var(--accent-color);
			box-shadow: 0 0 0 3px #00968824;
		}

		@media (width <= 64em) {
			display: flex;
			width: 100%;
		}
	}
}

.scale-ctn {
	display: flex;
	flex-direction: column;
	align-items: center;

	h2 {
		margin-block: 0 2rem;
	}

	@media (width >= 64.2em) {
		padding: 2rem;
	}
}

.scale-render {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 1rem;

	list-style: none;
	padding: 0 1rem;
	margin: 0;

	&::before {
		content: "";
		height: 2px;
		width: 100%;
		background-color: var(--dark-color);
		position: absolute;
		top: 50%;
		left: 0;
		z-index: -1;

		@media (width <= 64em) {
			display: none;
		}
	}

	@media (width <= 64em) {
		width: 100dvw;
		overflow-x: scroll;
		overflow-y: hidden;
		-webkit-overflow-scrolling: touch;
		padding-block: 2rem;
		padding-inline-start: 2rem;
		scroll-snap-type: x mandatory;
	}
}

.scale-render__item {
	flex-shrink: 0;
	display: flex;
	align-items: center;
	justify-content: center;

	width: 60px;
	aspect-ratio: 1;

	color: #fff;
	font-family: "DM Serif Display", serif;
	font-size: 1.3rem;

	border: 2px solid;
	background-color: var(--body-color);
	border-radius: 100vmax;
}

.scale-render__item:empty {
	background-color: var(--dark-color);
	border-color: transparent;
	scale: 0.75;
}

.scale-render__item:is(.is-root) {
	background-color: var(--accent-color);
	border-radius: 0.75rem;
}

.scale-training {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	margin-block-end: 2rem;

	button {
		border: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 4rem;
		aspect-ratio: 1;
		border-radius: 0.7rem;
		background-color: var(--accent-color);
		color: #fff;
		font-size: 1rem;
		cursor: pointer;

		svg {
			transition: rotate 0.3s;
		}

		&:hover,
		&:focus-visible,
		&:focus,
		&:active {
			svg {
				rotate: 180deg;
			}
		}
	}
}

.scale-training__result {
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	padding: 1em;
	margin-block-end: 2rem;
	gap: 0.5rem;

	span {
		display: flex;

		font-family: "DM Serif Display", serif;
		font-size: 2rem;

		&:nth-child(even) {
			color: var(--accent-color);
		}
	}
}
